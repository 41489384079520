<template>
	<div id="getWidth">
		<form
			@submit.prevent="addressInfoSubmit"
			@keydown="clearError($event.target.name);"
			@change="clearError($event.target.name);">
			<b-row v-if="!hideNameFields">
				<b-col
					cols="12"
					:md="validatePersonalDocument ? '4' : '6'">
					<input-text
						:id="'first_name'"
						:required="true"
						:label="translate('first_name')"
						:setter-value="form.first_name"
						:errors="errors[`${prefixErrors}first_name`]"
						type="text"
						autocomplete="given-name"
						@dataChanged="form.first_name = $event" />
				</b-col>
				<b-col
					cols="12"
					:md="validatePersonalDocument ? '4' : '6'">
					<input-text
						:id="'last_name'"
						:required="true"
						:label="translate('last_name')"
						:setter-value="form.last_name"
						:errors="errors[`${prefixErrors}last_name`]"
						type="text"
						autocomplete="family-name"
						@dataChanged="form.last_name = $event" />
				</b-col>
				<b-col
					v-if="validatePersonalDocument"
					cols="12"
					md="4">
					<input-text
						:id="'personal_document_number'"
						:required="true"
						:label="translate(`nin_${countryCode.toLowerCase()}`)"
						:setter-value="form.personal_document_number"
						:errors="errors[`${prefixErrors}personal_document_number`]"
						type="text"
						@dataChanged="form.personal_document_number = $event" />
				</b-col>
			</b-row>
			<b-row v-if="validatePersonalDocument">
				<b-col
					cols="12"
					md="6">
					<b-form-group
						class="media-files"
						label-for="personal_document_front_file">
						<template #label>
							{{ translate(`nin_${countryCode.toLowerCase()}_front`) }}
							<span class="text-danger">*</span>
						</template>
						<template v-if="currentPersonalDocumentFrontFile">
							<div class="d-flex align-items-center">
								<b-img
									style="max-width: 100px;"
									:src="currentPersonalDocumentFrontFile"
									:alt="translate(`nin_${countryCode.toLowerCase()}_front`)"
									class="img-fluid personal-document-image"
									@click="showImageViewer = true" />
								<image-viewer
									v-model="showImageViewer"
									:image-src="currentPersonalDocumentFrontFile" />
								<b-button
									variant="secondary"
									size="sm"
									class="ml-3"
									@click="form.personal_document_front_file = null; currentPersonalDocumentFrontFile = null">
									{{ translate('change') }}
								</b-button>
							</div>
						</template>
						<template v-else-if="form.personal_document_front_file">
							<div class="d-flex align-items-center">
								<div class="text-truncate">
									{{ form.personal_document_front_file.name }}
								</div>
								<b-button
									variant="danger"
									size="sm"
									class="ml-3"
									@click="form.personal_document_front_file = null">
									{{ translate('remove') }}
								</b-button>
							</div>
						</template>
						<template v-else>
							<b-form-file
								id="personal_document_front_file"
								v-model="form.personal_document_front_file"
								:class="hasError('personal_document_front_file') ? 'is-invalid' : ''"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								:accept="IMAGES_EXT.map((ext) => `.${ext}`).join(', ')"
								class="text-truncate"
								type="file"
								@change="clearError(`${prefixErrors}personal_document_front_file`)" />
							<b-form-text id="personal_document_front_file">
								{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
							</b-form-text>
						</template>
						<template v-if="hasError('personal_document_front_file')">
							<span
								v-for="error in errors[`${prefixErrors}personal_document_front_file`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-form-group>
				</b-col>
				<b-col
					cols="12"
					md="6">
					<b-form-group
						class="media-files"
						label-for="personal_document_back_file">
						<template #label>
							{{ translate(`nin_${countryCode.toLowerCase()}_back`) }}
							<span class="text-danger">*</span>
						</template>
						<template v-if="currentPersonalDocumentBackFile">
							<div class="d-flex align-items-center">
								<b-img
									style="max-width: 100px;"
									:src="currentPersonalDocumentBackFile"
									:alt="translate(`nin_${countryCode.toLowerCase()}_back`)"
									class="img-fluid personal-document-image"
									@click="showImageViewer = true" />
								<image-viewer
									v-model="showImageViewer"
									:image-src="currentPersonalDocumentBackFile" />
								<b-button
									variant="secondary"
									size="sm"
									class="ml-3"
									@click="form.personal_document_back_file = null; currentPersonalDocumentBackFile = null">
									{{ translate('change') }}
								</b-button>
							</div>
						</template>
						<template v-else-if="form.personal_document_back_file">
							<div class="d-flex align-items-center">
								<div class="text-truncate">
									{{ form.personal_document_back_file.name }}
								</div>
								<b-button
									variant="danger"
									size="sm"
									class="ml-3"
									@click="form.personal_document_back_file = null">
									{{ translate('remove') }}
								</b-button>
							</div>
						</template>
						<template v-else>
							<b-form-file
								id="personal_document_back_file"
								v-model="form.personal_document_back_file"
								:class="hasError('personal_document_back_file') ? 'is-invalid' : ''"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								:accept="IMAGES_EXT.map((ext) => `.${ext}`).join(', ')"
								class="text-truncate"
								type="file"
								@change="clearError(`${prefixErrors}personal_document_back_file`)" />
							<b-form-text id="personal_document_back_file">
								{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
							</b-form-text>
						</template>
						<template v-if="hasError('personal_document_back_file')">
							<span
								v-for="error in errors[`${prefixErrors}personal_document_back_file`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					:lg="hideCountryField ? '6' : '4'"
					cols="12">
					<input-text
						:id="'address'"
						:required="true"
						:label="translate('address')"
						:setter-value="form.address"
						:errors="errors[`${prefixErrors}address`]"
						type="text"
						autocomplete="address-line1"
						@dataChanged="form.address = $event" />
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					:lg="hideCountryField ? '6' : '4'"
					cols="12">
					<input-text
						:id="'address2'"
						:label="translate('address2')"
						:setter-value="form.address2"
						:errors="errors[`${prefixErrors}address2`]"
						type="text"
						autocomplete="address-line2"
						@dataChanged="form.address2 = $event" />
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<input-text
						:id="'city'"
						:required="true"
						:label="translate('city')"
						:setter-value="form.city"
						:errors="errors[`${prefixErrors}city`]"
						type="text"
						autocomplete="address-level2"
						@dataChanged="form.city = $event" />
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<input-text
						:id="'postcode'"
						:required="true"
						:label="translate('postcode')"
						:setter-value="form.postcode"
						:errors="errors[`${prefixErrors}postcode`]"
						type="text"
						autocomplete="postal-code"
						@dataChanged="form.postcode = $event" />
				</b-col>
				<b-col
					v-if="!hideCountryField"
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<div
						class="form-group">
						<label
							for="country_code"
							class="label">{{ translate('country') }}</label>
						<select
							id="country_code"
							v-model="form.country_code"
							:disabled="userCountry && !allowChangeCountry"
							:class="hasError('country_code') ? 'is-invalid' : ''"
							name="country_code"
							:autocomplete="allowChangeCountry ? 'country-name' : 'off'"
							class="form-control">
							<template v-if="!userCountry || allowChangeCountry">
								<option value="">
									{{ translate('select_country') }}
								</option>
								<option
									v-for="country in countries"
									:key="country.id"
									:value="country.attributes.code">
									{{ translate(country.attributes.code.toLowerCase()) }}
								</option>
							</template>
							<template v-if="userCountry && !allowChangeCountry">
								<option :value="countryCode">
									{{ translate(countryCode.toLowerCase()) }}
								</option>
							</template>
						</select>
						<template v-if="hasError('country_code')">
							<span
								v-for="error in errors[`${prefixErrors}country_code`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<div
						class="form-group">
						<label
							for="region_id"
							class="label">{{ translate('state') }}</label>
						<select
							id="region_id"
							v-model="form.region_id"
							:class="hasError('region_id') ? 'is-invalid' : ''"
							name="region_id"
							autocomplete="off"
							class="form-control">
							<option value="">
								{{ translate('select_state') }}
							</option>
							<option
								v-for="state in states"
								:key="state.id"
								:value="state.id">
								{{ state.attributes.name }}
							</option>
						</select>
						<template v-if="hasError('region_id')">
							<span
								v-for="error in errors[`${prefixErrors}region_id`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
				</b-col>
				<b-col
					v-if="displayOverrideToggle"
					:class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : ''"
					cols="12">
					<switch-toggle
						id="override_validation"
						v-model="form.override_validation"
						name="override_validation"
						variant="success"
						class="mr-2"
						pill />
					<label for="override_validation">
						{{ translate('override_validation') }}
						<span
							v-b-tooltip.hover
							:title="translate('override_validation_info')"
							class="text-muted">
							<i class="fas fa-lg fa-info-circle" />
						</span>
					</label>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					v-if="displaySubmitButton || displayCancelButton || displayGobackButton"
					class="mt-3">
					<button
						v-if="displaySubmitButton"
						class="btn ml-2  btn-primary float-right"
						@click="$emit('on-submit', form)">
						{{ translate('submit') }}
					</button>
					<button
						v-if="displayCancelButton"
						class="btn btn-secondary b-4 float-right"
						@click="$emit('on-cancel')">
						{{ translate('cancel') }}
					</button>
					<button
						v-if="displayGobackButton"
						class="btn btn-secondary b-4 float-right"
						@click="$router.go(-1)">
						{{ translate('go_back') }}
					</button>
				</b-col>
			</b-row>
		</form>
	</div>
</template>
<script>
import { ADDRESSES } from '@/settings/AddressBook';
import InputText from '@/components/InputText';
import SwitchToggle from '@/components/Switch';
import {
	Profile, Countries, Addresses, NIN,
} from '@/translations';
import ElementResolution from '@/mixins/ElementResolution';
import WindowSizes from '@/mixins/WindowSizes';
import EventBus from '@/util/eventBus';
import { IMAGES_EXT } from '@/settings/Media';
import ImageViewer from '@/components/ImageViewer/index.vue';

export default {
	name: 'AddressForm',
	messages: [Profile, Countries, Addresses, NIN],
	components: { ImageViewer, SwitchToggle, InputText },
	mixins: [ElementResolution, WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		sameAsBilling: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		prefixErrors: {
			type: String,
			default: '',
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		address: {
			type: String,
			default: ADDRESSES.default,
		},
		countries: {
			type: Array,
			required: true,
		},
		countryCodeImport: {
			type: String,
			default: '',
		},
		states: {
			type: Array,
			required: true,
		},
		userCountry: {
			type: Boolean,
			default: false,
		},
		displayCancelButton: {
			type: Boolean,
			default: false,
		},
		displaySubmitButton: {
			type: Boolean,
			default: true,
		},
		displayOverrideToggle: {
			type: Boolean,
			default: false,
		},
		displayGobackButton: {
			type: Boolean,
			default: true,
		},
		allowChangeCountry: {
			type: Boolean,
			default: false,
		},
		hideCountryField: {
			type: Boolean,
			default: false,
		},
		hideNameFields: {
			type: Boolean,
			default: false,
		},
		validatePersonalDocument: {
			type: Boolean,
			default: false,
		},
		currentPersonalDocumentFrontFileImport: {
			type: String,
			default: '',
		},
		currentPersonalDocumentBackFileImport: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			countryCode: this.countryCodeImport,
			selectedCountryIso: '',
			IMAGES_EXT,
			currentPersonalDocumentFrontFile: this.currentPersonalDocumentFrontFileImport,
			currentPersonalDocumentBackFile: this.currentPersonalDocumentBackFileImport,
			showImageViewer: false,
		};
	},
	watch: {
		currentPersonalDocumentFrontFileImport(value) {
			this.currentPersonalDocumentFrontFile = value;
		},
		currentPersonalDocumentBackFileImport(value) {
			this.currentPersonalDocumentBackFile = value;
		},
		errorsImport(value) {
			this.errors = value;
		},
		'form.country_code': function onCountryChange(value) {
			if (value !== '' && value !== undefined) {
				this.$emit('country-change', value);
				this.form.region_id = this.formImport.region_id || '';
			}
		},
		formImport: {
			handler(value) {
				this.form = value;
			},
			deep: true,
		},
		countryCodeImport(value) {
			if (value !== '') {
				this.countryCode = value;
			} else {
				this.countryCode = this.$user.details().country ? this.$user.details().country.iso_code_2 : '';
			}
		},
	},
	mounted() {
		if (!this.userCountry) this.form.country_code = '';
		if (!this.countryCode) this.countryCode = this.$user.details().country ? this.$user.details().country.iso_code_2 : '';
		EventBus.$on('callAddressRequestData', () => {
			this.addressInfoSubmit();
		});
	},
	methods: {
		addressInfoSubmit() {
			this.$emit('update:formImport', this.form);
		},
		hasError(field) {
			if (typeof this.errors[`${this.prefixErrors}${field}`] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>

<style scoped>
.personal-document-image:hover {
	cursor: pointer;
	outline: 1px solid #df703d;
}
</style>
